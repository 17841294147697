export const getResourceUrl = ({ nodeType, slug }): string => {
  const prefixMapping = {
    NewsItem: 'news',
    Post: 'blog',
    Event: 'events',
    Download: 'downloads',
    Guide: 'guides',
    CustomerStory: 'case-studies',
  };

  return `/${prefixMapping[nodeType]}/${slug}`;
};

export const getResourceCategory = (nodeType: string): string => {
  const categoryMapping = {
    NewsItem: 'News',
    Post: 'Blog',
    Event: 'Event',
    Guide: 'Guide',
    CustomerStory: 'Customer Story',
  };

  return categoryMapping[nodeType];
};
