import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import { useToggle } from 'react-use';
import ArrowDown from './ArrowDown';

interface MobileNavAccordionProps {
  label: string;
  items: {
    path: string;
    label: string;
    icon?: {
      sourceUrl: string;
      altText: string;
    };
    description?: string;
  }[];
  onClose: () => void;
  initialOpen?: boolean;
}

const MobileNavAccordion: React.FC<MobileNavAccordionProps> = ({
  label,
  items,
  initialOpen = false,
  onClose,
}) => {
  const [open, toggleOpen] = useToggle(initialOpen);

  return (
    <div>
      <button
        className="flex items-center justify-between w-full py-5 font-semibold text-left focus:outline-none"
        onClick={toggleOpen}
      >
        {label}
        <ArrowDown
          className={`transition-all transform ${open && '-rotate-180'}`}
        />
      </button>

      <motion.div
        className="px-5 -mx-5 overflow-hidden bg-true-gray-100"
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ type: 'tween', duration: 0.3 }}
      >
        <div className="py-10 space-y-6">
          {items.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={onClose}
              className="flex items-start"
            >
              {item.icon && (
                <div className="flex-shrink-0 pr-7">
                  <img
                    src={item.icon.sourceUrl}
                    alt={item.icon.altText}
                    className="object-contain w-7 h-7"
                  />
                </div>
              )}

              <div>
                <div className="mb-1 text-sm font-semibold">{item.label}</div>
                {item.description && (
                  <div className="text-xs leading-normal text-body-copy">
                    {item.description}
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default MobileNavAccordion;
