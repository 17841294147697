import { AnimatePresence, motion } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { normalizeUrl } from '../../helpers/url';
import NavItem from '../atoms/NavItem';

interface NavProps {
  headerHeight: number;
}

const Nav: React.FC<NavProps> = ({ headerHeight }) => {
  const {
    wp: {
      options: {
        globalOptions: { platformMenuHeading, logInLabel, getStartedLabel },
      },
    },
    wpMenu: {
      menuItems: { nodes },
    },
    companyMenu: {
      menuItems: { nodes: companyMenuNodes },
    },
    solutionsMenu: {
      menuItems: { nodes: solutionsMenuNodes },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            logInLabel
            getStartedLabel
            platformMenuHeading
          }
        }
      }
      wpMenu(name: { eq: "Header Nav" }) {
        menuItems {
          nodes {
            label
            url
            headerMenuItem {
              dropdownType
            }
          }
        }
      }
      companyMenu: wpMenu(name: { eq: "Company Dropdown Nav" }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      solutionsMenu: wpMenu(name: { eq: "Solutions Dropdown Nav" }) {
        menuItems {
          nodes {
            label
            url
            platformMenuItem {
              description
              icon {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const [dropdownOpen, setDropdownOpen] = useState('none');

  return (
    <nav className="hidden lg:block">
      <ul className="flex items-center text-black space-x-9">
        {nodes.map(({ url, label, headerMenuItem }, index) => (
          <li key={index} style={{ lineHeight: '64px' }}>
            <NavItem
              to={normalizeUrl(url)}
              label={label}
              onMouseEnter={() => setDropdownOpen(headerMenuItem.dropdownType)}
              onMouseOut={() => setDropdownOpen('none')}
            >
              <AnimatePresence>
                {dropdownOpen === 'company' &&
                  headerMenuItem.dropdownType === 'company' && (
                    <motion.div
                      className="absolute leading-none bg-white shadow-md -left-10 top-full"
                      variants={{ off: { opacity: 0 }, on: { opacity: 1 } }}
                      initial="off"
                      animate="on"
                      exit="off"
                    >
                      <div className="w-56 px-10 pt-10 pb-10 ">
                        <ul className="space-y-6">
                          {companyMenuNodes?.map(({ url, label }, index) => (
                            <li key={index}>
                              <Link
                                to={normalizeUrl(url)}
                                className="font-semibold text-black transition hover:text-primary-red focus:text-primary-red focus:outline-none"
                              >
                                {label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </motion.div>
                  )}
              </AnimatePresence>
              <AnimatePresence>
                {dropdownOpen === 'solutions' &&
                  headerMenuItem.dropdownType === 'solutions' && (
                    <motion.div
                      className="fixed left-0 right-0 bg-white shadow-md"
                      style={{ top: headerHeight }}
                      variants={{ off: { opacity: 0 }, on: { opacity: 1 } }}
                      initial="off"
                      animate="on"
                      exit="off"
                    >
                      <div className="pt-6">
                        <div className="pl-10 mb-5 text-lg font-semibold">
                          {platformMenuHeading}
                        </div>
                        <ul className="flex justify-between w-full">
                          {solutionsMenuNodes?.map(
                            (
                              {
                                url,
                                label,
                                platformMenuItem: { description, icon },
                              },
                              index,
                            ) => (
                              <li
                                key={index}
                                className="justify-between flex-1 flex-shrink-0"
                                style={{ width: '20%' }}
                              >
                                <Link
                                  to={normalizeUrl(url)}
                                  className="block h-full pt-10 pl-10 pr-10 font-semibold text-black transition pb-9 hover:text-primary-red focus:text-primary-red focus:outline-none hover:bg-primary-red hover:bg-opacity-5"
                                >
                                  <img
                                    src={icon.sourceUrl}
                                    alt={icon.altText}
                                    className="w-auto h-8 mb-10"
                                  />

                                  <div className="mb-2.5 leading-snug">
                                    {label}
                                  </div>
                                  <div className="text-sm font-normal text-body-copy">
                                    {description}
                                  </div>
                                </Link>
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </motion.div>
                  )}
              </AnimatePresence>
            </NavItem>
          </li>
        ))}

        <NavItem
          to="https://platform.spirable.com/accounts/login/?next=/dashboard/"
          target="_blank"
          label={logInLabel}
        />
        <li>
          <Link
            to="/get-started"
            className="inline-flex items-center justify-center h-16 px-8 font-semibold text-center text-white transition-colors duration-200 ease-in-out bg-primary-red text-15px hover:bg-black"
          >
            {getStartedLabel}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
