import { graphql, Link, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { normalizeUrl } from '../../helpers/url';
import FooterColumnComponent, {
  FooterColumnProps,
} from '../molecules/FooterColumn';

const LinkPartial: React.FC<any> = ({ url, label, index, length }) => (
  <>
    <Link
      to={normalizeUrl(url)}
      className="transition hover:underline focus:underline"
    >
      {label}
    </Link>
    {index < length - 1 && <div className="px-2">•</div>}
  </>
);

const Footer: React.FC = () => {
  const {
    wp: {
      options: {
        globalOptions: {
          footerTagline,
          contactSalesLabel,
          getStartedLabel,
          copyrightText,
        },
      },
    },
    copyrightMenu: {
      menuItems: { nodes: copyrightNodes },
    },
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            footerTagline
            contactSalesLabel
            getStartedLabel
            copyrightText
          }
        }
      }
      copyrightMenu: wpMenu(name: { eq: "Copyright Menu" }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      wpMenu(name: { eq: "Footer Nav" }) {
        name
        menuItems {
          nodes {
            label
            url
            childItems {
              nodes {
                url
                label
              }
            }
          }
        }
      }
    }
  `);

  const groups: FooterColumnProps[] = nodes
    .filter(({ childItems: { nodes } }) => nodes.length > 0)
    .map(({ label, childItems: { nodes } }) => ({
      heading: label,
      links: nodes.map(({ label, url }) => ({
        to: normalizeUrl(url),
        label,
      })),
    }));

  const columns: FooterColumnProps[][] = [
    // ...groups.slice(0, groups.length - 2).map((group) => [group]),
    // [...groups.slice(groups.length - 2)],
    ...groups.map((group) => [group]),
  ];

  return (
    <footer className="bg-almost-black">
      <div className="pt-16 pb-12 bg-right-top bg-no-repeat lg:bg-right bg-footer-mobile lg:bg-footer md:pt-24">
        <div className="items-start justify-between mx-auto mb-16 text-center u-container lg:flex md:mb-18 md:text-left">
          <div
            className="lg:w-5/12 text-white font-semibold text-4xl md:text-4xl xl:text-4.5xl tracking-pct leading-tight text-left"
            dangerouslySetInnerHTML={{ __html: footerTagline }}
          />
          <div className="lg:w-5/12 md:flex items-center lg:justify-end md:space-x-2.5 space-y-4 md:space-y-0 lg:pl-20 pt-8 lg:pt-3">
            <Link
              to="/contact"
              className="w-full transition-all rounded u-btn u-btn--white md:w-auto hover:bg-white hover:text-black hover:opacity-100"
            >
              {contactSalesLabel}
            </Link>
            <Link
              to="/get-started"
              className="w-full rounded u-btn u-btn--primary md:w-auto"
            >
              {getStartedLabel}
            </Link>
          </div>
        </div>
        <div className="flex u-container">
          <div className="flex flex-wrap justify-between flex-grow-0 pt-16 pb-12 border-t border-white md:pt-10 md:pb-28 border-opacity-10">
            {columns.map((column, index) => (
              <div
                className={`${
                  column.length === 1 ? 'w-1/2' : 'w-full flex md:block'
                } md:w-auto md:flex-1 md:space-y-5 mb-10 md:mb-0`}
                key={index}
              >
                {column.map((props, index) => (
                  <div
                    key={index}
                    className={column.length === 1 ? 'w-full' : 'flex-1'}
                  >
                    <FooterColumnComponent {...props} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between pb-8 leading-relaxed text-white md:justify-start u-container md:space-y-0 md:space-x-10 text-11px md:pb-0">
          <div dangerouslySetInnerHTML={{ __html: copyrightText }} />
          <div className="flex items-center">
            {copyrightNodes.map(({ label, url }, index) => (
              <LinkPartial
                key={index}
                label={label}
                url={url}
                index={index}
                length={copyrightNodes.length}
              />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
