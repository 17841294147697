import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import React from 'react';
import useSiteInfoAssets from '../../hooks/use-site-info-assets';
import Footer from '../organisms/Footer';
import Header from '../organisms/Header';

interface LayoutProps {
  headerBar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ headerBar, children }) => {
  const seo = useSiteInfoAssets();

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <div className="font-sans antialiased">
        <Header headerBar={headerBar} />
        <main>{children}</main>
        <Footer />
      </div>
    </SEOContext.Provider>
  );
};

export default Layout;
