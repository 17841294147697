import { AnimatePresence } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useMeasure, useToggle } from 'react-use';
import Logo from '../atoms/Logo';
import HeaderBar from '../molecules/HeaderBar';
import MobileMenu from '../molecules/MobileMenu';
import MobileNav from '../molecules/MobileNav';
import Nav from '../molecules/Nav';

interface HeaderProps {
  background?: string;
  headerBar?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  background = 'bg-white',
  headerBar,
}) => {
  const [ref, { height }] = useMeasure();
  const [open, toggleOpen] = useToggle(false);

  const {
    wp: {
      options: {
        globalOptions: { headerBarAnnouncement },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            headerBarAnnouncement {
              ... on WpPost {
                title
                nodeType
                slug
              }
              ... on WpEvent {
                title
                nodeType
                slug
              }
              ... on WpNewsItem {
                title
                nodeType
                slug
              }
              ... on WpGuide {
                title
                nodeType
                slug
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="sticky top-0 z-30" ref={ref}>
      {headerBar && headerBarAnnouncement && !open && (
        <HeaderBar {...headerBarAnnouncement} />
      )}
      <div
        className={`h-16 flex items-center justify-between pl-5 xl:pl-9 transition z-20 ${background}`}
      >
        <Link to="/" className="transition hover:opacity-75">
          <Logo className="h-7" />
        </Link>

        <Nav headerHeight={height} />
        <MobileNav open={open} onToggle={toggleOpen} />
      </div>
      <AnimatePresence>
        {open && <MobileMenu onClose={() => toggleOpen(false)} />}
      </AnimatePresence>
    </div>
  );
};

export default Header;
