import React from 'react';

const ArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.36553 8.70075C6.95392 9.31862 6.04609 9.31862 5.63448 8.70075L1.08174 1.86658C0.621307 1.17543 1.11678 0.249998 1.94726 0.249998L11.0527 0.249999C11.8832 0.249999 12.3787 1.17543 11.9183 1.86658L7.36553 8.70075Z"
      fill="black"
    />
  </svg>
);

export default ArrowDown;
