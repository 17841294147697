import { motion, Variants } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { useToggle } from 'react-use';
import ArrowDown from '../atoms/ArrowDown';
import MobileNavAccordion from '../atoms/MobileNavAccordion';
import MobileNavItem from '../atoms/MobileNavItem';

const variants: Variants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

interface MobileMenuProps {
  onClose: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ onClose }) => {
  const [solutionsOpen, toggleSolutionsOpen] = useToggle(false);
  const [companyOpen, toggleCompanyOpen] = useToggle(false);
  const [sectionOpen, setSectionOpen] = useState<string | null>(null);

  const {
    wp: {
      options: {
        globalOptions: { logInLabel, getStartedLabel },
      },
    },
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            logInLabel
            getStartedLabel
          }
        }
      }
      wpMenu(name: { eq: "Mobile Nav" }) {
        menuItems {
          nodes {
            label
            parentId
            path
            childItems {
              nodes {
                url
                label
                path
                menuItem {
                  description
                  icon {
                    sourceUrl
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <motion.div
      className="fixed bottom-0 left-0 right-0 z-50 flex flex-col bg-white top-16"
      variants={variants}
      initial="closed"
      animate="open"
      exit="closed"
    >
      <div className="flex-grow overflow-y-auto bg-tertiary">
        <div className="py-6 mx-5">
          {nodes
            .filter((node) => node.parentId === null)
            .map((node, index) =>
              node.childItems.nodes.length > 0 ? (
                <MobileNavAccordion
                  key={index}
                  label={node.label}
                  items={node.childItems.nodes.map((childNode) => ({
                    path: childNode.path,
                    label: childNode.label,
                    description: childNode.menuItem.description,
                    icon: childNode.menuItem.icon,
                  }))}
                  initialOpen={node.label === 'Solutions'}
                  onClose={onClose}
                />
              ) : (
                <MobileNavItem
                  key={index}
                  label={node.label}
                  path={node.path}
                  onClose={onClose}
                />
              ),
            )}
        </div>
      </div>
      <div className="py-5 mx-5 flex items-center space-x-1.5">
        <div className="flex-1">
          <Link
            to="https://platform.spirable.com/accounts/login/?next=/dashboard/"
            target="_blank"
            className="block w-full px-8 py-4 font-semibold text-center text-black bg-white border border-black rounded border-opacity-20 text-15px"
          >
            {logInLabel}
          </Link>
        </div>
        <div className="flex-1">
          <Link
            to="/get-started"
            className="block w-full px-8 py-4 font-semibold text-center text-white border rounded bg-primary-red text-15px border-primary-red"
          >
            {getStartedLabel}
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileMenu;
