import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

interface MobileNavProps {
  open: boolean;
  onToggle: (next?: boolean) => void;
}

const MobileNav: React.FC<MobileNavProps> = ({ open, onToggle }) => {
  const {
    wp: {
      options: {
        globalOptions: { getStartedLabel },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            getStartedLabel
          }
        }
      }
    }
  `);

  return (
    <div className="flex items-center lg:hidden space-x-2.5 mr-4">
      <Link
        to="/get-started"
        className="py-3 text-xs font-semibold rounded-sm px-4.5 bg-primary-red text-white  focus:outline-none block"
        onClick={() => onToggle(false)}
      >
        {getStartedLabel}
      </Link>
      <button
        className={`flex items-center justify-center w-10 h-10 p-0 m-0 rounded-sm ${
          open
            ? 'border-primary-red border-opacity-100'
            : 'border border-black border-opacity-20'
        } focus:outline-none`}
        onClick={() => onToggle()}
      >
        {open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#000"
              strokeWidth="1.5"
              d="M1 1l16 16M1 17L17 1"
            ></path>
          </svg>
        ) : (
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 7H20" stroke="black" strokeWidth="1.5" />
            <path d="M0 1H20" stroke="black" strokeWidth="1.5" />
            <path d="M0 13H20" stroke="black" strokeWidth="1.5" />
          </svg>
        )}
      </button>
    </div>
  );
};

export default MobileNav;
