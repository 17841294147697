import React from 'react';

const ArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (


  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.12297 6.09614L2.12297 1.09614C1.93437 0.913983 1.68177 0.813189 1.41957 0.815467C1.15738 0.817746 0.906564 0.922915 0.721156 1.10832C0.535748 1.29373 0.430579 1.54454 0.428301 1.80674C0.426022 2.06894 0.526817 2.32154 0.708975 2.51014L5.00197 6.80314L0.708975 11.0961C0.613464 11.1884 0.537282 11.2987 0.484873 11.4207C0.432464 11.5427 0.404878 11.674 0.403724 11.8067C0.40257 11.9395 0.427872 12.0712 0.478153 12.1941C0.528434 12.317 0.602687 12.4286 0.69658 12.5225C0.790472 12.6164 0.902124 12.6907 1.02502 12.741C1.14792 12.7912 1.2796 12.8165 1.41238 12.8154C1.54516 12.8142 1.67638 12.7867 1.79838 12.7342C1.92038 12.6818 2.03073 12.6057 2.12297 12.5101L7.12297 7.51014C7.31045 7.32261 7.41576 7.06831 7.41576 6.80314C7.41576 6.53798 7.31045 6.28367 7.12297 6.09614V6.09614Z" fill="currentColor"/>
  </svg>
);

export default ArrowRight;
