import { Link } from 'gatsby';
import React from 'react';
import { getResourceCategory, getResourceUrl } from '../../helpers/resources';
import ArrowRight from '../atoms/ArrowRight';

interface HeaderBarProps {
  title: string;
  nodeType: string;
  slug: string;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ title, nodeType, slug }) => (
  <div className="hidden w-fulltext-white lg:flex">
    <Link
      to={getResourceUrl({ slug, nodeType })}
      className="block w-full p-3 mx-auto text-sm font-semibold leading-5 text-center text-white transition bg-black hover:bg-opacity-90"
    >
      <div className="inline-flex items-center mx-auto">
        <span className="mr-2.5">
          {getResourceCategory(nodeType).toUpperCase()} | {title}
        </span>
        <ArrowRight className="relative flex-shrink-0 h-3 -top-px" />
      </div>
    </Link>
  </div>
);

export default HeaderBar;
