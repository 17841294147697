import { Link } from 'gatsby';
import React from 'react';

interface NavItemProps {
  to: string;
  label: string;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  label,
  onMouseEnter,
  onMouseOut,
  children,
}) => (
  <div
    className="relative inline-block"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseOut}
  >
    <Link
      to={to}
      className="text-sm font-medium leading-5 transition hover:text-primary-red focus:text-primary-red"
    >
      {label}
    </Link>
    {children}
  </div>
);

export default NavItem;
