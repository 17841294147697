import { Link as LinkComponent } from 'gatsby';
import React from 'react';
import { ICTA } from '../../interfaces/cta';

export interface FooterColumnProps {
  heading: string;
  links: ICTA[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ heading, links }) => (
  <div className="flex-1 pr-9 lg:pr-16">
    <h2 className="mb-4 font-semibold leading-6 text-white md:mb-5">{heading}</h2>
    <ul className="space-y-1.5 md:space-y-2.5">
      {links.map(({ to, label }, index) => (
        <li key={index}>
          {to.startsWith('/') ? (
            <LinkComponent
              to={to}
              className="text-sm leading-snug transition md:text-sm text-tertiary opacity-70 hover:opacity-100 focus:opacity-100 md:whitespace-nowrap"
            >
              {label}
            </LinkComponent>
          ) : (
            <a
              href={to}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm leading-snug transition md:text-sm text-tertiary opacity-70 hover:opacity-100 focus:opacity-100 md:whitespace-nowrap"
            >
              {label}
            </a>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default FooterColumn;
