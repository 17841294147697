import { Link } from 'gatsby';
import React from 'react';

interface MobileNavItemProps {
  path: string;
  label: string;
  onClose: () => void;
}

const MobileNavItem: React.FC<MobileNavItemProps> = ({
  path,
  label,
  onClose,
}) => (
  <div>
    <Link
      to={path}
      className="block py-5 font-semibold focus:outline-none"
      onClick={onClose}
    >
      {label}
    </Link>
  </div>
);

export default MobileNavItem;
